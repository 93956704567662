import React from 'react'
import Sidebar from '../Sidebar'
import GlobalIncome from './GlobalIncome'

const GlobalIncomeda = () => {
  return (
    <>
           <div className="animated-background">
            <div className='container-fulid' > 
            <div className='row' >
                <div className='col-lg-2 col-md-2 p-0' >
                   <Sidebar />
                </div>
                <div className='col-lg-10 col-md-12 p-0'>
                   <GlobalIncome/>
                </div>
             
            </div>
        </div>
        </div>
    </>
  )
}

export default GlobalIncomeda
