import React, { useState,useEffect } from 'react';
import './Login.css';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { apiurl } from '../../env';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


function SignUp() {
  const [searchParams] = useSearchParams();

  const Navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [number, setNumber] = useState('');
  const [password, setPassword] = useState('');
  const [password_c, setPasswordConfirmation] = useState('');
  const [name, setName] = useState('');
  const [refid, setRefid] = useState(searchParams.get("refer_id") || '');
  const [refName, setRefName] = useState(searchParams.get("name") || '');
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState();


  const [apiName, setApiName] = useState('');
  const [apiPass, setApiPass] = useState('');
  const [show, setShow] = useState(false);
  const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text)
          .then(() => alert('Copied to clipboard'))
          .catch((error) => console.error('Error copying to clipboard: ', error));
  };


  const handleClose = () =>{ 
    
    Navigate("/");
    setShow(false)};
  const handleShow = () => setShow(true);

  const signup = async()=>{
    setLoading(true);
    var myHeaders = new Headers();

const formdata = new FormData();
formdata.append("name", name);
formdata.append("email", email);
formdata.append("username", username);
formdata.append("number", number);
formdata.append("password", password);
formdata.append("password_confirmation", password_c);
formdata.append("position", selectedOption);
formdata.append("refer_id", refid.slice(0,-1));

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(`${apiurl}/registration`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
    if (result.status === '001') {
      setTimeout(() => {
        handleShow()
      }, 1000); 
      setApiName(result.username);
      setApiPass(result.password);
      setName('');
      setRefid('');
      setRefName('');
      setEmail('');
      setNumber('')
      setPassword('')
      setPasswordConfirmation('')
      setSelectedOption('')
    } else {
      toast.error(result.message);
    }
  
  })
  .catch(error => console.log(error))
  .finally(()=>{setLoading(false)})
  }
  useEffect(() => {
    // Check the last character of refid to determine the selected radio button
    if (refid.endsWith('L')) {
      setSelectedOption('left');
    } else if (refid.endsWith('R')) {
      setSelectedOption('right');
    }
  }, [refid]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setRefid(value);
    if (value.endsWith('L')) {
      setSelectedOption('left');
    } else if (value.endsWith('R')) {
      setSelectedOption('right');
    }
  };

  const handleRadioChangeRecharge = (event) => {
    setSelectedOption(event.target.value);
  };


  useEffect(() => {
    // Make API call when refid change
    const fetchData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);


        const formdata = new FormData();
        formdata.append("referId", refid.slice(0,-1));

        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };

        const response = await fetch(`${apiurl}/get-user`, requestOptions);
        const result = await response.json();
        console.log(result);
        setRefName(result.name);
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchData();
  }, [refid])
  return (
    <>
<ToastContainer/>
<Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Authentication Credential</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><b>User Name : </b>{apiName}</p>
                    <p><b>Password : </b>{apiPass}</p>
                    <p>*Please Copy These Credential</p>
                </Modal.Body>
                <Modal.Footer style={{justifyContent:'start',alignItems:'start'}} >
                    
                    <Button variant="primary" onClick={() => copyToClipboard(apiName)}>
                        Copy Username
                    </Button>
                    <Button variant="primary" onClick={() => copyToClipboard(apiPass)}>
                        Copy Password
                    </Button>
                    <Button variant="secondary" className='mt' onClick={handleClose}>
                     Go To Login
                    </Button>
                </Modal.Footer>
            </Modal>
      <div class="container app">

<div class="login1">

    <h1>Sign Up </h1>

    <div className='form1'>
        <div class="input-box">
        </div>

         <div className='row'>
         <div className='col-lg-6'>
         <div className="input-box">
        <label htmlFor="email" style={{color:'#fff'}}> Name</label>
              <input
                type="name"  value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
         </div>
         <div className='col-lg-6'>
         <div className="input-box">
        <label htmlFor="email" style={{color:'#fff'}}>Mobile Number</label>
              <input
                type="number"
                maxLength="10"
                value={number}
                // onChange={(e) => setNumber(e.target.value)}
                onChange={(e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10) {
      setNumber(inputValue);
    }
  }}
                required
              />
            </div>
         </div>
         </div>
         <div className='row'>
         <div className='col-lg-6'>
         <div className="input-box">
        <label htmlFor="email" style={{color:'#fff'}}> Password</label>
              <input
                type="password"
              
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
         </div>
         <div className='col-lg-6'>
         <div className="input-box">
           <label htmlFor="email" style={{color:'#fff'}}>Confirm Password</label>
              <input
                type="password"
                  value={password_c}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
            </div>
         </div>
         </div>
            <div className="input-box mt-2">
           <label htmlFor="email" style={{color:'#fff'}}>Enter Email</label>
              <input
                type="email"
               
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-box mt-2">
           <label htmlFor="email" style={{color:'#fff'}} >Username (Optional)</label>
              <input
                type="text"
               placeholder='Enter Existing Username'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
   
      <div>
      <div className="input-box mt-2">
        <label htmlFor="email" style={{ color: '#fff' }}> Refer ID</label>
        <input
          type="text"
          value={refid}
          onChange={handleInputChange}
          disabled
        />
      </div>
      <p style={{color:'#fff'}}>{refName == null ? 'undefined' : `${refName}`}</p>
      <div
        className="d-flex p-2"
        style={{
          borderRadius: "10px",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          color: "#F23534",
        }}
      >
        {/* <div style={{ display: 'flex' }}>
          <input
            type="radio"
            name="simtype"
            id="right"
            value="right"
            checked={selectedOption === 'right'}
            onChange={handleRadioChangeRecharge}
            disabled
          />
          <label htmlFor="right" className="ms-2 pt-2" style={{
            cursor: 'pointer',
            color: selectedOption === 'right' ? '#04076a' : '#fff'
          }}>
            Right
          </label>
        </div> */}

{/* 
        <div style={{ display: 'flex', paddingLeft: '10px' }}>
          <input
            type="radio"
            name="simtype"
            id="left"
            value="left"
            checked={selectedOption === 'left'}
            onChange={handleRadioChangeRecharge}
            disabled
            style={{ color: 'red' }} />
          <label htmlFor="left" className="ms-2 pt-2" style={{
            cursor: 'pointer', color:
              selectedOption === 'left' ? '#04076a' : '#fff'
          }}>
            Left
          </label>
        </div> */}
      </div>
    </div>
    </div>
            

        <button onClick={()=>{signup()}} className="sumit mt-3">
            {loading==false?"Register":"Loading...."}
            </button>

        <div class="links">
            <div className="register-link">
              <p>
                Don't have an account? <NavLink to="/" style={{fontSize:'16px',color:'#160961',fontWeight:'600'}}>Login</NavLink>
              </p>
            </div>
        </div>

    </div>

</div>
</div>
    </>
  );
}

export default SignUp;
