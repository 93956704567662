import React ,{useState,useEffect} from 'react'
import { apiurl } from '../env';
import { NavLink } from 'react-router-dom';

const TransactionData = () => {
const [data, setdata] = useState([])
const [data1, setdata1] = useState([])
const [first, setfirst] = useState(0);
const [loading, setLoading] = useState(false);
const storedName = localStorage.getItem("name");


const Depositdata = async()=>{
  setLoading(true);

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var formdata = new FormData();
formdata.append("type", "deposit");

var requestOptions = {
method: 'POST',
headers: myHeaders,
body: formdata,
redirect: 'follow'
};

fetch(`${apiurl}/txn-history`, requestOptions)
.then(response => response.json())
.then(result => {console.log(result)
  if(result.status == '001'){
     setdata(result.data)
  }
  else{

  }
 })
 .catch(error => console.log('error', error)).finally(()=>{setLoading(false)})
}

const Withdraw = async()=>{
  setLoading(true);

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var formdata = new FormData();
formdata.append("type", "withdraw");

var requestOptions = {
method: 'POST',
headers: myHeaders,
body: formdata,
redirect: 'follow'
};

fetch(`${apiurl}/txn-history`, requestOptions)
.then(response => response.json())
.then(result => {console.log(result)
  if(result.status == '001'){
     setdata1(result.data)
  }
  else{

  }
 })
 .catch(error => console.log('error', error)).finally(()=>{setLoading(false)})
}

useEffect(() => {
  Depositdata()
  Withdraw()
}, [])


  return (
    <>
          <section >
        <div className="container-fluid desktop  " style={{background: 'linear-gradient(to right bottom, #4f525c, #2f323b)', width: '100%' }}>
          <div className="row">
            <div className="col-lg-10 col-md-12 p-3">
              <p style={{ textAlign: 'center', color: '#fff', fontSize: '20px' }}>Welcome {storedName}</p>
            </div>
            <div className="col-lg-2 col-md-12">
              <div style={{ width: "100%", padding: "10px" }}>
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle "
                    style={{ color: "#fff", float: "right", fontSize: "25px" }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <h5 className='my mx-3'>Transaction History</h5>

      <div className='container-fluid mt-5' >
    <div className='row'>
   <div className='col-lg-12'>
   <div className='d-flex tabs-container'>
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => {setfirst(0);}}
          className={first === 0 ? 'active' : ''}
        >
          <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>Deposit History</p>
        </h6>
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => setfirst(1)}
          className={first === 1 ? 'active' : ''}
        >
        <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>Withdraw History</p>

        </h6>
      </div>

        {
        first==0?<>
        <div className='container' >
<div className='row mt-3 text-center'>
<div className='col-lg-12'>
  
<div class="table-responsive text-nowrap  p-3" 
style={{borderRadius:'10px',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}>
  <div className='res' style={{scrollX: true}}>
  <table class="table table-striped " >
<thead>
<tr>
    <th style={{textAlign:'center',}}>S.no</th>
    <th style={{textAlign:'center',}}>User_Id</th>
    <th style={{textAlign:'center',}}>Transaction_Type</th>
    <th style={{textAlign:'center',}}>Amount</th>
    <th style={{textAlign:'center',}}>Currency</th>
    <th style={{textAlign:'center',}}>Address</th>
    <th style={{textAlign:'center',}}>Status</th>
    <th style={{textAlign:'center',}}>Date/Time</th>


  </tr>
</thead>
<tbody>
  {data.length > 0 ? (
    data.map((res, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{res.user_id}</td>
        <td>{res.transaction_type}</td>
        <td>{res.amount}</td>
        <td>{res.currency}</td>
        <td>{res.address}</td>
        <td>{res.status}</td>
        <td>{res.datetime}</td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="8">No data found</td>
    </tr>
  )}
</tbody>
</table>
  </div>
  </div>
</div>
</div>
</div>
        </>:first==1?<>
        <div className='container' >
<div className='row mt-3 text-center'>
<div className='col-lg-12'>
  
<div class="table-responsive text-nowrap p-3" style={{borderRadius:'10px',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}>
<div className='res' style={{scrollX: true}}>
  <table class="table table-striped " >
<thead>
  <tr>
    <th style={{textAlign:'center',}}>S.no</th>
    <th style={{textAlign:'center',}}>User_Id</th>
    <th style={{textAlign:'center',}}>Transaction_Type</th>
    <th style={{textAlign:'center',}}>Amount</th>
    <th style={{textAlign:'center',}}>Currency</th>
    <th style={{textAlign:'center',}}>Address</th>
    <th style={{textAlign:'center',}}>Status</th>
    <th style={{textAlign:'center',}}>Date/Time</th>


  </tr>
</thead>
<tbody>
  {data1.length > 0 ? (
    data1.map((res, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{res.user_id}</td>
        <td>{res.transaction_type}</td>
        <td>{res.amount}</td>
        <td>{res.currency}</td>
        <td>{res.address}</td>
        <td>{res.status}</td>
        <td>{res.datetime}</td>
      </tr>
    ))
  ) : (
    <div >
      <p colSpan="8" style={{textAlign:'center',color:'#fff',}} className='mt-2'>No data found</p>
    </div>
      

  )}
</tbody>

</table>
  </div>
  </div>
</div>
</div>
</div>
        </>:null
    }
            </div>
    </div>
    </div>


    </>
  )
}

export default TransactionData