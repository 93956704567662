import React, { useState, useEffect } from 'react'
import code from '../Img/qr.png'
import { NavLink, json, useNavigate } from 'react-router-dom';
import { apiurl } from '../env';
import { toast, ToastContainer } from 'react-toastify';

const DepositFundData = () => {
  const Navigate = useNavigate();
  const [data, setdata] = useState('')
  const [getC, setGetC] = useState([]);
  const [amount, setamount] = useState()
  const [address, setaddress] = useState()
  const [password, setPassword] = useState()
  const [currency, setcurrency] = useState()
  const [currencyname, setcurrencyname] = useState()
  const [qrcode, setcode] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showSecondForm, setShowSecondForm] = useState(false);
  const [fund, setfund] = useState();
  const [first, setfirst] = useState(0);

  const handleButtonClick = () => {
    setShowSecondForm(!showSecondForm);
  };
  const storedName = localStorage.getItem("name");

  const getCurrency = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = await fetch(`${apiurl}/get-currency`, requestOptions);
      const result = await response.json();

      if (result.status === '001') {
        setGetC(result.data);
      }
    } catch (error) {
      console.log('Error fetching currency:', error);
    }
  };

  const handleWithdraw = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

    var formdata = new FormData();
    formdata.append("amount", amount);
    formdata.append("address", address);
    formdata.append("password", password);
    formdata.append("currency", currency);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    try {
      const response = await fetch(`${apiurl}/withdraw-request`, requestOptions);
      const result = await response.json();

      if (result.status === '001') {
        setShowSecondForm(true);
        setPassword('')
        setaddress('')
        setamount('')
        setcurrency('')

      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log('Error withdrawing funds:', error);
    } finally {
      setLoading(false);
    }
  };

  const handledeposit = async()=>{
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var formdata = new FormData();
formdata.append("amount", amount);
formdata.append("currency", currency);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(`${apiurl}/coin-deposit`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
    if(result.status == '001'){
      toast(result.message)
      setdata(result.data.result)
      setcode(result.data.result)
    }
    else{
      toast.error(result.message)
    }
  })
  .catch(error => console.log('error', error)).finally(()=>{setLoading(false)})
  }


  const DashbordApi = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${apiurl}/get-dashboard`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setdata(result)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getCurrency()
    DashbordApi()
  }, [])

console.log(currency);
  return (
    <>
      <ToastContainer />
      <section >
        <div className="container-fluid desktop px-3" style={{ background: 'linear-gradient(to right bottom, #4f525c, #2f323b)', width: '100%' }}>
          <div className="row">
            <div className="col-lg-10 col-md-12 p-3">
              <p style={{ textAlign: 'center', color: '#fff', fontSize: '20px' }}>Welcome {storedName}</p>
            </div>
            <div className="col-lg-2 col-md-12">
              <div style={{ width: "100%", padding: "10px" }}>
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle "
                    style={{ color: "#fff", float: "right", fontSize: "25px" }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    <div className='mt-2'>
    <div className='d-flex tabs-container mx-4 mt-5'>
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => setfirst(0)}
          className={first === 0 ? 'active' : ''}
        >
          <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>Withdraw Funds</p>
        </h6>
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => setfirst(1)}
          className={first === 1 ? 'active' : ''}
        >
        <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>Deposit Funds</p>

        </h6>
      </div>
    </div>
      <div className='container px-4 mt-1' style={{ height: '78vh', overflowY: 'scroll' }} >
     

        {
          first ==0?<>

          <div style={{ background: '#222223', borderRadius: '10px', padding: "10px" }}>
          <div className='row mt-1 px-3'>
      <h5 className=''>Withdraw Funds</h5>
            <div className='col-lg-4'>
              <div class="card1 mb-3 p-3 text-center">
                <h5 class="cp">Fund  Balance </h5>
                <hr style={{ color: '#fff' }} />
                <h5 class="ca">${data.fund_balance}</h5>
              </div>
              <div class="card1 mb-3 p-3 text-center">
                <h5 class="cp"> Wallet Balance </h5>
                <hr style={{ color: '#fff' }} />
                <h5 class="ca">${data.wallet_balance}</h5>
              </div>
            </div>
            <div className='col-lg-6'>
  {qrcode == 1 ? <div style={{ background: '#343434', borderRadius: "10px" }}>
    <div style={{ background: 'linear-gradient(to right bottom, #4f525c, #2f323b)', borderRadius: '1px' }}>
      <p style={{ padding: '10px', color: '#fff', fontWeight: 600, fontSize: "18px" }}>Add Funds</p>
    </div>
    <div>
      {!showSecondForm && (
        <div className="mb-3 p-4">
          <label htmlFor="exampleFormControlInput2" className="f mt-3">
            Enter Amount
          </label>
          <input
            style={{ color: 'blue', backgroundColor: '#fff' }} 
            value={amount}
            onChange={(e) => { setamount(e.target.value) }}
            type="text"
            className="form-control"
            id="exampleFormControlInput2"
            placeholder="Enter Amount"
          />
          <label htmlFor="exampleFormControlInput2" className="f mt-3">
            Enter Address
          </label>
          <input
            style={{ color: 'blue', backgroundColor: '#fff' }}
            value={address}
            onChange={(e) => { setaddress(e.target.value) }}
            type="text"
            id="exampleFormControlInput2"
            placeholder="Enter Address"
            className="form-control"
          />
          <label htmlFor="exampleFormControlInput2" className="f mt-3">
            Enter Password
          </label>
          <input
            style={{ color: 'blue' }}
            value={password}
            onChange={(e) => { setPassword(e.target.value) }}
            type="text"
            id="exampleFormControlInput2"
            className="form-control"
            placeholder="Enter Password"
          />

          <label htmlFor="exampleFormControlInput3" className="f mt-3">
            Coin:
          </label>
          <select
                      className="w-100 "
                      style={{
                        background: "#fff",
                        borderRadius: "10px",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "16px",
                        color: "#545B7A",
                        border: "none",
                        padding: '10px'
                      }}
                      onChange={(event) => {
                        const state = event.target.selectedOptions[0];
                        setcurrency(state.getAttribute('id'));
                        // setcurrency(state.getAttribute('id'));
                      }}
                    >
                      {/* Default option with a placeholder text */}
                      <option value="" disabled selected>Select State</option>

                      {/* Mapping through the state array */}
                      {getC?.map((res) => {
                        return (
                          <option key={res.id} id={res.id} value={res.id}>
                            {res.name}
                          </option>
                        );
                      })}
                    </select>




          <div className="btn1 mt-4" onClick={handleWithdraw}>
            {loading ? 'Loading...' : 'Proceed'}
          </div>
        </div>
      )}

      {/* Second Form */}
      {showSecondForm && (
        <div className="mt-4 text-center mb-3">
          <p htmlFor="exampleFormControlInput4" className="f">
            Additional Field
          </p>
          <img src={qrcode.qrcode_url} />
          <p className='mt-3' style={{ color: '#fff' }}>Currency in TRX: 987654567</p>
          <p className='mb-3' style={{ color: '#fff', padding: "10px" }}>When the clicked, showSecondForm is rendered, allowing the second form to appear.</p>
          <button className="btn1 mt-4 mx-2 mb-3" onClick={handleButtonClick}>
            Proceed
          </button>
        </div>
      )}
    </div>
  </div> :

    <div style={{ background: '#5a618c', borderRadius: '10px', padding: "10px", justifyContent: "center", alignItems: "center" }}>

      <div className="mt-4 text-center mb-3">
        <p htmlFor="exampleFormControlInput4" className="f">
          Additional Field
        </p>
        <img src={qrcode.qrcode_url} alt='qr code' style={{ marginLeft: "20" }} />
        <p className='mt-3' style={{ color: '#fff' }}>\000</p>
        {/* <button className="btn1 mt-4 mx-2 mb-3" onClick={handleButtonClick}>
            
      </button> */}
      </div>

    </div>

  }
</div>
          </div>
        </div>
          </>:first ==1?<>

          <div style={{ background: '#222223', borderRadius: '10px', padding: "10px" }}>
          <div className='row mt-2 px-3'>
      <h5 className=''>Deposit Funds</h5>
            <div className='col-lg-4'>
              <div class="card1 mb-3 p-3 text-center">
                <h5 class="cp">Fund  Balance </h5>
                <hr style={{ color: '#fff' }} />
                <h5 class="ca">${data.fund_balance}</h5>
              </div>
              <div class="card1 mb-3 p-3 text-center">
                <h5 class="cp">Wallet Balance </h5>
                <hr style={{ color: '#fff' }} />
                <h5 class="ca">${data.wallet_balance}</h5>
              </div>
            </div>
            <div className='col-lg-6'>
  {qrcode == 1 ? <div style={{ background: '#343434', borderRadius: "10px" }}>
    <div style={{ background: 'linear-gradient(to right bottom, #4f525c, #2f323b)', borderRadius: '1px' }}>
      <p style={{ padding: '10px', color: '#fff', fontWeight: 600, fontSize: "18px" }}>Add Funds</p>
    </div>
    <div>
      {!showSecondForm && (
        <div className="mb-3 p-4">
          <label htmlFor="exampleFormControlInput2" className="f mt-3">
            Enter Amount
          </label>
          <input
            style={{ color: 'blue', backgroundColor: '#fff' }} 
            value={amount}
            onChange={(e) => { setamount(e.target.value) }}
            type="text"
            className="form-control"
            id="exampleFormControlInput2"
            placeholder="Enter Amount"
          />
        
          <label htmlFor="exampleFormControlInput3" className="f mt-3">
            Coin:
          </label>
          <select
                      className="w-100 "
                      style={{
                        background: "#fff",
                        borderRadius: "10px",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "16px",
                        color: "#545B7A",
                        border: "none",
                        padding: '10px'
                      }}
                      onChange={(event) => {
                        const state = event.target.selectedOptions[0];
                        setcurrency(state.getAttribute('id'));
                        setcurrencyname(state.getAttribute('name'));
                      }}
                    >
                      <option value="" disabled selected>Select State</option>

                      {/* Mapping through the state array */}
                      {getC?.map((res) => {
                        return (
                          <option key={res.id} id={res.id} value={res.id} name={res.name}>
                            {res.name}
                          </option>
                        );
                      })}
                    </select>




          <div className="btn1 mt-4" onClick={handledeposit}>
            {loading ? 'Loading...' : 'Proceed'}
          </div>
        </div>
      )}

      {/* Second Form */}
      {showSecondForm && (
        <div className="mt-4 text-center mb-3">
          <p htmlFor="exampleFormControlInput4" className="f">
            Additional Field
          </p>
          <img src={qrcode.qrcode_url} />
          <p className='mt-3' style={{ color: '#fff' }}>Currency in TRX: 987654567</p>
          <p className='mb-3' style={{ color: '#fff', padding: "10px" }}>When the clicked, showSecondForm is rendered, allowing the second form to appear.</p>
          <button className="btn1 mt-4 mx-2 mb-3" onClick={handledeposit
          }>
            Proceed
          </button>
        </div>
      )}
    </div>
  </div> :

    <div style={{ background: '#5a618c', borderRadius: '10px', padding: "10px", justifyContent: "center", alignItems: "center" }}>

      <div className="mt-4 text-center mb-3">
        <p htmlFor="exampleFormControlInput4" className="f">
          Additional Field
        </p>
        <img src={qrcode.qrcode_url} alt='qr code' style={{ marginLeft: "20" }} />
        <p className='mt-3' style={{ color: '#fff' }}>{currencyname}: {qrcode.amount}</p>
        {/* <button className="btn1 mt-4 mx-2 mb-3" onClick={handleButtonClick}>
            
      </button> */}
      </div>

    </div>

  }
</div>
          </div>
        </div>
          </>:null
        }
      </div>


    </>
  )
}

export default DepositFundData