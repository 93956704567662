import React, { useEffect, useState, useRef } from "react";
import "../component/Text.css";
import Lottie from "lottie-react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import btn from "../Img/coin-btc.png";
import { SiFirewalla } from "react-icons/si";
import { apiurl } from "../env";
import MyChartComponent from "./MyChartComponent";
import { SiWallabag } from "react-icons/si";
import { toast, ToastContainer } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import p14 from "../Img/Animation - 1705480758874.json";
import btn2 from "../Img/coin-dash.png";
import btn1 from "../Img/coin-lite.png";
import btn3 from "../Img/ico-d-1.png";
import btn4 from "../Img/ico-d-2.png";
import btn5 from "../Img/ico-d-3.png";
import { RiShieldUserFill } from "react-icons/ri";
import { GiVendingMachine } from "react-icons/gi";
import { TbBusinessplan } from "react-icons/tb";
import { MdAnimation } from "react-icons/md";
import { BiArrowToTop } from "react-icons/bi";
import { MdForkLeft } from "react-icons/md";
import { MdOutlineForkRight } from "react-icons/md";
import { GiSesame } from "react-icons/gi";
import { MdOutlineRotate90DegreesCw } from "react-icons/md";
import { MdOutlineCloseFullscreen } from "react-icons/md";


const HomeData = () => {
  const [searchParams] = useSearchParams();
  const [data, setdata] = useState([]);
  const [username, setUsername] = useState(searchParams.get("name") || "");
  const [history, setHistory] = useState();
  const [show, setShow] = useState(false);
  const [refName, setRefName] = useState(searchParams.get("name") || "");
  const inputRef = useRef(null);
  const storedName = localStorage.getItem("name");
  const [copySuccess, setCopySuccess] = useState(null);
  const [leftMessage, setLeftMessage] = useState("");
  const [rightMessage, setRightMessage] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isDarkMode, setIsDarkMode] = useState(false);


  const handleCopyRight = (message) => {
    navigator.clipboard
      .writeText(message)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.error("Copy failed:", err);
        setCopySuccess(false);
      });
  };

  const handleCopyLeft = (message) => {
    navigator.clipboard
      .writeText(message)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.error("Copy failed:", err);
        setCopySuccess(false);
      });
  };

  const profileApi = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiurl}/get-profile`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setLeftMessage(result.left);
        setRightMessage(result.right);
      })
      .catch((error) => console.log("error", error));
  };


  const useractive = async (handleClose) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      var formdata = new FormData();

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${apiurl}/activate-plan`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == "001") {
            toast(result.message);
            handleClose();
          } else if (result.status == "007") {
            toast(result.message);
            handleClose();
          } else {
            toast(result.message);
            handleClose();
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const DashbordApi = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiurl}/get-dashboard`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setdata(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    DashbordApi();
    profileApi();
  }, []);

  useEffect(() => {
    // Make API call when refid changes
    const fetchData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${localStorage.getItem("token")}`
        );

        const formdata = new FormData();
        formdata.append("referId", username);

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };

        const response = await fetch(`${apiurl}/get-user`, requestOptions);
        const result = await response.json();
        console.log(result);
        setRefName(result.name);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [username]);

  return (
    <>
      <ToastContainer />

      <section>
        <div
          className="container-fluid desktop px-3"
          style={{
            background: "linear-gradient(to right bottom, #4f525c, #2f323b)",
            width: "100%",
          }}
        >
          <div className="row">
            <div className="col-lg-12 col-md-12 p-3">
              <div className="d-flex justify-content-between align-content-center">
                <p style={{ color: "#fff", fontSize: "20px" }}>
                  Welcome {storedName}
                </p>
                <NavLink to="/Activeid">
                <button class="button-41" role="button" >
                  <span class="text">Activate Now</span>
                </button>
                </NavLink>
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle "
                    style={{
                      color: "#fff",
                      float: "right",
                      fontSize: "25px",
                      marginLeft: "90px",
                    }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="col-lg-12 col-md-8">
            {/* <marquee
              direction="left"
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
              }}
            >
        "Congratulations, dear USDT warriors! We are launching on February 12th, 2024. Get ready with your team!"
            </marquee> */}
          </div>
      <div
        className="container h-10 mt-5 mb-5"
        style={{ height: "88vh", overflowY: "scroll" }}
      >
        <div className="mt-3" style={{ marginBottom: "5%" }}>
          <div className="row px-3">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div class="r4_counter db_box ">
                <div class="stats">
                  <h3 class="color-white ">Name : {storedName}</h3>
                  <span>UserId : USDT001 </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div class="r4_counter db_box ">
                <div class="stats">
                  <h3 class="color-white ">Current Rank</h3>
                  <span style={{ textAlign: "center" }}>30</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div class="r4_counter db_box ">
                <div class="cryp-icon">
                  <img src={btn} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ {data.wallet_balance}</h3>
                  <span> Income Wallet </span>
                </div>
              </div>
            </div>
          </div>

        
          <div className="row px-3">
            <div className="col-lg-4 mt-2">
              <div class="r4_counter db_box ">
                <div class="icon-after cc BTC-alt">
                  <BiArrowToTop />
                </div>
                <div class="cryp-icon">
                  <img src={btn4} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ {data.total_income}</h3>
                  <span>Total Income</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div class="r4_counter db_box ">
                <div class="icon-after cc BTC-alt">
                  <SiFirewalla />
                </div>
                <div class="cryp-icon">
                  <img src={btn1} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ {data.fund_balance}</h3>
                  <span>P2P Wallet</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div class="r4_counter db_box ">
                <div class="icon-after cc BTC-alt">
                  <SiWallabag />
                </div>
                <div class="cryp-icon">
                  <img src={btn2} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ 0</h3>
                  <span>Total Withdrawal</span>
                  {/* <ertyjk */}
                </div>
              </div>
            </div>
          </div>
          <div className="row px-3">
            <div className="col-lg-4">
              <div class="r4_counter db_box ">
                <div class="cryp-icon">
                  <img src={btn5} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ 0</h3>
                  <span>Self Re-Entry</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div class="r4_counter db_box ">
                <div class="cryp-icon">
                  <img src={btn4} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ 0</h3>
                  <span>Team Re-Entry</span>
                </div>
              </div>
            </div>
         
            <div className="col-lg-4 col-md-6 col-sm-12 ">
              <div class="r4_counter db_box ">
                <div class="cryp-icon">
                  <img src={btn1} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ 0 </h3>
                  <span style={{fontSize:'16px'}}>Referral Bonus</span>
                </div>
              </div>
            </div>
          </div>


          <div className="row px-3">
          <div className="col-lg-4">
              <div class="r4_counter db_box1 ">
                <div class="stats1">
                  <h3 class="color-white ">Direct Team </h3>
                  <div style={{justifyContent:'space-around',alignItems:'center',display:'flex',marginTop: '-5px'}}>
                  <p class="color-white">Active</p>
                  <p class="color-white ">Inactive</p>
                  </div>
                  <div style={{justifyContent:'space-around',alignItems:'center',display:'flex',marginTop: '-0px' }}>
                  <p class="color-white ">0</p>
                  <p class="color-white ">10</p>

                  </div>
                </div>
                
              </div>
            </div>
            <div className="col-lg-4">
              <div class="r4_counter db_box1 ">
                <div class="stats1">
                  <h3 class="color-white ">Total Team </h3>
                  <div style={{justifyContent:'space-around',alignItems:'center',display:'flex',marginTop: '-4px'}}>
                  <p class="color-white ">Active</p>
                  <p class="color-white ">Inactive</p>
                  </div>
                  <div style={{justifyContent:'space-around',alignItems:'center',display:'flex',marginTop: '-0px' }}>
                  <p class="color-white ">0</p>
                  <p class="color-white ">10</p>

                  </div>
                </div>
                
              </div>
            </div>
            <div className="col-lg-4">
              <div class="r4_counter db_box1 ">
                <div class="stats1">
                  <h3 class="color-white ">Binary Team </h3>
                  <div style={{justifyContent:'space-around',alignItems:'center',display:'flex',marginTop: '-10px'}}>
                  <p class="color-white ">Left</p>
                  <p class="color-white ">Right</p>
                  </div>
                  <div style={{justifyContent:'space-around',alignItems:'center',display:'flex',marginTop: '-17px' }}>
                  <p class="color-white" ><small>Active -10</small></p>
                  <p class="color-white "><small>Active -10</small></p>
                  </div>
                  <div style={{justifyContent:'space-around',alignItems:'center',display:'flex',marginTop: '-17px' }}>
                  <p class="color-white "><small>Inactive -10</small></p>
                  <p class="color-white "><small>Inactive -10</small></p>
                  </div>
                </div>
                
              </div>
            </div>
      
          </div>

          <div className="row px-3">
          <div className="col-lg-4">
              <div class="r4_counter db_box ">
                <div class="cryp-icon">
                  <img src={btn3} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ {data.total_matching_income}</h3>
                  <span>Total Matching Income</span>
                </div>
              </div>
              <div className="row">
              <div className="col-lg-12">
              <div class="r4_counter db_box ">
                <div class="cryp-icon">
                  <img src={btn5} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ 0</h3>
                  <span>Self Complete Bonus</span>
                </div>
              </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 ">
              <div class="r4_counter db_box ">
                <div class="cryp-icon">
                  <img src={btn3} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ 0</h3>
                  <span>Reword Income</span>
                </div>
              </div>
            </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div class="r4_counter db_box ">
                <div class="cryp-icon">
                  <img src={btn4} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ 0</h3>
                  <span>Net 14 Income</span>
                </div>
              </div>
              <div className="row">
              <div className="col-lg-12">
              <div class="r4_counter db_box ">
                <div class="cryp-icon">
                  <img src={btn1} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ 0</h3>
                  <span>Downline Automation Income</span>
                </div>
              </div>
            </div>
            
            <div className="col-lg-12">
              <div class="r4_counter db_box ">
                <div class="icon-after cc BTC-alt">
                  <RiShieldUserFill />
                </div>
                <div class="cryp-icon">
                  <img src={btn4} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">{data.total_server}</h3>
                  <span>Total Plans</span>
                </div>
              </div>
            </div>
              </div>
            </div>
            <div className="col-lg-4 mt-1">
              <div className="card1">
                <h1 className="title">Refer Link</h1>
                <p className="desc">
                  <small>Left Assign Link</small>
                </p>
                <div className="input-group mb-3 px-3">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-wallet"></i>
                  </span>
                  <input
                    value={leftMessage}
                    onChange={(e) => setLeftMessage(e.target.value)}
                    type="text"
                    className="form-control"
                    aria-label="Left Message Link"
                    aria-describedby="basic-addon1"
                    style={{ color: "#fff" }}
                  />
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    }}
                    onClick={() => handleCopyLeft(leftMessage)}
                  >
                    <i className="bi bi-copy" style={{ color: "#fff" }}></i>
                  </span>
                </div>
                <p className="desc">
                  <small>Right Assign Link</small>
                </p>
                <div className="input-group mb-3 px-3">
                  <span className="input-group-text" id="basic-addon1">
                    @
                  </span>
                  <input
                    value={rightMessage}
                    onChange={(e) => setRightMessage(e.target.value)}
                    type="text"
                    className="form-control"
                    aria-label="Right Message Link"
                    aria-describedby="basic-addon1"
                    style={{ color: "#fff" }}
                  />
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    }}
                    onClick={() => handleCopyRight(rightMessage)}
                  >
                    <i className="bi bi-copy" style={{ color: "#fff" }}></i>
                  </span>
                </div>
                {copySuccess && (
                  <p style={{ color: "green" }}>Copied Successfully!</p>
                )}
              </div>
            </div>
          </div>

          <div className="row px-3 mt-3">
    
            <div className="col-lg-4 col-md-6 col-sm-12 ">
              <div class="r4_counter db_box ">
                <div class="icon-after cc BTC-alt">
                  <i class="bi bi-currency-bitcoin"></i>
                </div>
                <div class="cryp-icon">
                  <img src={btn1} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ {data.total_global_income}</h3>
                  <span> Total Global Income</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 ">
              <div class="r4_counter db_box ">
                <div class="icon-after cc BTC-alt">
                  <SiWallabag />
                </div>
                <div class="cryp-icon">
                  <img src={btn2} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ {data.total_invested}</h3>
                  <span>Total Invested</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body
          style={{
            background: "#000",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Lottie
            className="Lottie w-25" // Add the class name here
            animationData={p14} // replace with your animation data
            loop
            autoplay
            style={{ marginLeft: "170px" }}
          />
          <p style={{ textAlign: "center", color: "#fff", fontSize: "18px" }}>
            Are You Sure, You Want To Activate <br /> Plan Amount: $30{" "}
          </p>
        </Modal.Body>
        <Modal.Footer style={{ background: "#000" }}>
          <Button variant="secondary" onClick={handleClose}>
            Go Back
          </Button>
          <Button variant="primary" onClick={() => useractive(handleClose)}>
            Activate
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HomeData;
