import React, { useState, useRef, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import "../src/Sidebar.css"; 
import logo from "../src/Img/login.png";
import { Container, Offcanvas, Navbar, Nav, Image } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io"; 
import { toast, ToastContainer } from "react-toastify"; 
import Accordion from "react-bootstrap/Accordion"; 


function SideBar() {
  const Navigate = useNavigate();
  const location = useLocation();
  const selectRef = useRef(null);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClosetoggal = () => setShow(false);
  const handleShowtoggal = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const offsetValue = -56;

  const handleLogout = () => {
    localStorage.removeItem("token");
    Navigate("/");
  };

  const [selectedOption, setSelectedOption] = useState();
  //  const locat = useLocation() 

 
  //  const sty = locat.pathname === "/User" ? {  backgroundColor:"red", fontWeight: '600' } : { backgroundColor: "#191c3d", fontWeight: '600', zIndex: '999' };

  return (
    <div>
      <ToastContainer />
      <div
        className="container-fluid desktop"
        style={{ backgroundColor: "#2f323b" }}
      >
        <div
          className="row px-2"
          style={{ height: "100vh", overflowY: "scroll" }}
        >
          <div className="col-lg-12 col-sm-12">
            <div
              className="sidbar d-flex flex-column flex-shrink-0 "
              style={{ height: "158vh", overflowY: "scroll" }}
            >
              <div className="mt-2">
                <img
                  src={logo}
                  alt="logo"
                  className="sidebarLogo"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>

              <ul className="nav nav-pills flex-column mt-3">
                <li className="nav-item">
                  <NavLink to="/Home" className="nav-link" aria-current="page">
                    <i className="bi bi-microsoft me-2"></i>
                    Dashboard 
                  </NavLink>
                </li>  
          
                

                <Accordion
      defaultActiveKey="1"
      className=""
      style={{ border: "none", backgroundColor: "red",  }}
    >
      <Accordion.Item
        eventKey="0"
        style={{ border: "none", backgroundColor: "#2f323b" }}
      >
        <Accordion.Header>Profile Management</Accordion.Header>
        <Accordion.Body>
          <li className="nav-item" style={{ width: "100%" }}>
            <NavLink
              to="/User"
              className="nav-link"
              aria-current="page"
            >
              Profile
            </NavLink>
          </li>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

                <Accordion
                  defaultActiveKey="2"
                  style={{ border: "none", backgroundColor: "transparent" }}
                  className="mt-1"
                >
                  <Accordion.Item
                    eventKey="0"
                    style={{ border: "none", backgroundColor: "#2f323b" }}
                  >
                    <Accordion.Header>Network Area</Accordion.Header>
                    <Accordion.Body>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/MyTeam"
                          className="nav-link"
                          aria-current="page"
                        >
                          My Team
                        </NavLink>
                      </li>
                      <li className="nav-item" style={{ width: "100%" }}>
                        <NavLink
                          to="/TreeGenelogy"
                          className="nav-link"
                          aria-current="page"
                        >
                          Genelogy Tree
                        </NavLink>
                      </li>
                      <li className="nav-item mt-2" style={{width:'100%'}}>
                <NavLink to="/SlotGenelogyTree" className="nav-link" aria-current="page">
                   Binary Tree
                  </NavLink>
                </li>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion
                  defaultActiveKey="3"
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  <Accordion.Item
                    eventKey="0"
                    style={{ border: "none", backgroundColor: "#2f323b" }}
                  >
                    <Accordion.Header> Income Area</Accordion.Header>
                    <Accordion.Body>
                      <li className="nav-item" style={{ width: "100%" }}>
                        <NavLink
                          to="/Income"
                          className="nav-link"
                          aria-current="page"
                        >
                          Matching Income
                        </NavLink>
                      </li>
                      {/* <li className="nav-item mt-2" style={{width:'100%'}}>
                  <NavLink to="/GlobalIncomeda" className="nav-link" aria-current="page">
                    Global Income
                  </NavLink>
                </li> */}
                      <li className="nav-item mt-1" style={{ width: "100%" }}>
                        <NavLink
                          to="/ReferralBonus"
                          className="nav-link"
                          aria-current="page"
                          style={{}}
                        >
                          Referral Bonus
                        </NavLink>
                      </li>
                      <li className="nav-item mt-1" style={{ width: "100%" }}>
                        <NavLink
                          to="/LifeTimeRewordIncome"
                          className="nav-link"
                          aria-current="page"
                          style={{ fontSize: "14px", textDecoration: "none" }}
                        >
                          Life Time Reword Income
                        </NavLink>
                      </li>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/DownlineAutomationIncome"
                          className="nav-link"
                          aria-current="page"
                        >
                          Downline Automation Income
                        </NavLink>
                      </li>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/SelfcompleteBonus"
                          className="nav-link"
                          aria-current="page"
                        >
                          Self Complete Bonus
                        </NavLink>
                      </li>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/Network14Income"
                          className="nav-link"
                          aria-current="page"
                        >
                          Network 14 Income
                        </NavLink>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion
                  defaultActiveKey="4"
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  <Accordion.Item
                    eventKey="0"
                    style={{ border: "none", backgroundColor: "#2f323b" }}
                  >
                    <Accordion.Header> Wallet Area</Accordion.Header>
                    <Accordion.Body>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/P2P"
                          className="nav-link"
                          aria-current="page"
                        >
                          P2P Wallet{" "}
                        </NavLink>
                      </li>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/FundConvert"
                          className="nav-link"
                          aria-current="page"
                        >
                          Fund Convert
                        </NavLink>
                      </li>
                      {/* <li className="nav-item mt-2" style={{width:'100%'}}>
                  <NavLink to="/IncomeWallatehistory" className="nav-link" aria-current="page">
                    Income Wallet (History)
                  </NavLink>
                </li> */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion
                  defaultActiveKey="5"
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  <Accordion.Item
                    eventKey="0"
                    style={{ border: "none", backgroundColor: "#2f323b" }}
                  >
                    <Accordion.Header> Topup</Accordion.Header>
                    <Accordion.Body style={{ backgroundColor: "#2f323b" }}>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/Activeid"
                          className="nav-link"
                          aria-current="page"
                        >
                          Activate ID
                        </NavLink>
                      </li>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/ActiveHistory"
                          className="nav-link"
                          aria-current="page"
                        >
                          History
                        </NavLink>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                 

                {/* <li className="nav-item mt-3">
                  <NavLink
                    to="/MyTeam"
                    className="nav-link"
                    aria-current="page"
                  >
                    <i class="bi bi-microsoft-teams me-2"></i>
                    My Team
                  </NavLink>
                </li> */}
                <li className="nav-item mt-3">
                  <NavLink
                    to="/DepositFund"
                    className="nav-link"
                    aria-current="page"
                  >
                    <i class="bi bi-cash-stack me-2"></i>
                    Deposit Funds
                  </NavLink>
                </li>
                {/* <li className="nav-item mt-3">
                  <NavLink to="/P2P" className="nav-link" aria-current="page">
                    <i class="bi bi-arrow-left-right me-2"></i>P 2 P Transfer
                  </NavLink>
                </li> */}
                <li className="nav-item mt-3">
                  <NavLink
                    to="/Transaction"
                    className="nav-link"
                    aria-current="page"
                  >
                    <i class="bi bi-wallet2 me-2"></i>
                    Transactions
                  </NavLink>
                </li>
                {/* <li className="nav-item mt-3">
                  <NavLink
                    to="/PurchasePaln"
                    className="nav-link"
                    aria-current="page"
                  >
                    <i class="bi bi-receipt me-2"></i>
                    Plan History
                  </NavLink>
                </li> */}

                {/* <li className="nav-item mt-3">
                  <NavLink
                    to="/Income"
                    className="nav-link"
                    aria-current="page"
                  >
                    <FaBitcoin size={20} />
                    <span className="ms-2">Incomes</span>
                  </NavLink>
                </li> */}
                {/* <li className="nav-item mt-3">
                <NavLink to="/User" className="nav-link" aria-current="page">
                <i class="bi bi-person-circle me-2"></i>
                  Profile
                </NavLink>
              </li> */}
              </ul>
              <li
                className="nav-item text-center  mt-5 b-0 "
                style={{
                  color: "black",
                  listStyle: "none",
                  background: "#fff",
                  padding: "10px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  handleLogout();
                }}
              >
                <div>
                  <i
                    class="bi bi-box-arrow-right me-2 "
                    style={{ color: "black" }}
                  ></i>
                  Logout
                </div>
              </li>
            </div>
          </div>
        </div>
      </div>

      <Navbar
        bg="dark"
        variant="dark"
        className="mobileview"
        expand={false}
        fixed="top"
        style={{ backgroundColor: "red", width: "100%" }}
      >
        <Container fluid>
          <Navbar.Toggle onClick={handleShowtoggal} style={{ width: "50px" }} />
          <div className="usericn">
            {/* <NavLink to="/Home" style={{ textDecoration: "none",borderBottom:'none'}}>
            <i className="bi bi-person-circle mb-2 mx-4" style={{ color: '#fff', fontSize: "30px",border:'none'}}></i>
          </NavLink> */}
            {/* <button class="button-41" role="button" onClick={handleShow1}>
              <span class="text">Activate Now</span>
            </button> */}
          </div>
          <div className="col-lg-12 col-md-8">
            {/* <marquee
              direction="left"
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
              }}
            >
              "Congratulations, dear USDT warriors! We are launching on February
              12th, 2024. Get ready with your team!"
            </marquee> */}
          </div>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
            style={{
              width: "80%",
              backgroundColor: "#2f323b",
              height: "100vh",
              overflowY: "scroll",
            }}
            className="sidbar px-3"
            show={show}
            onHide={handleClosetoggal}
          >
            <Offcanvas.Header>
              <IoMdClose
                onClick={handleClosetoggal}
                style={{ fontSize: "2rem" }}
              />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="sidbar justify-content-start flex-grow-1 pe-3 offcanvas--menu">
                <NavLink
                  to="/Home"
                  spy={true}
                  smooth={true}
                  offset={offsetValue}
                  duration={500}
                  className="p-3 text-decoration-none"
                  style={{ color: "#fff",}} // Add backgroundColor style
                  aria-current="page"
                  activeClassName="active-link"
                >
                  <i className="bi bi-microsoft me-2"></i>
                  Dashboard
                </NavLink>
                {/* <Link
                  to="/MyTeam"
                  spy={true}
                  smooth={true}
                  offset={offsetValue}
                  duration={500}
                  className="p-3 text-decoration-none"
                  style={{ color: "#fff" }}
                >
                  <i class="bi bi-microsoft-teams me-2"></i>
                  My Team
                </Link> */}

                <NavLink
                  to="/DepositFund"
                  spy={true}
                  smooth={true}
                  offset={offsetValue}
                  duration={500}
                  className="p-3  text-decoration-none"
                  style={{ color: "#fff" }}
                  activeClassName="active-link"
                >
                  <i class="bi bi-cash-stack me-2"></i>
                  Deposit Funds
                </NavLink>

                <NavLink
                  to="/Transaction"
                  spy={true}
                  smooth={true}
                  offset={offsetValue}
                  duration={500}
                  className="p-3  text-decoration-none"
                  style={{ color: "#fff" }}
                  activeClassName="active-link"
                >
                  <i class="bi bi-wallet2 me-2"></i>
                  Transactions
                </NavLink>

         
                <Accordion
                  defaultActiveKey="1"
                  className=""
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  <Accordion.Item
                    eventKey="0"
                    style={{ border: "none", backgroundColor: "#2f323b" }}
                  >
                    <Accordion.Header> Profile Management</Accordion.Header>
                    <Accordion.Body>
                      <li className="nav-item" style={{ width: "100%" }}>
                        <NavLink
                          to="/User"
                          className="nav-link"
                          aria-current="page"
                        >
                          Profile
                        </NavLink>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion
                  defaultActiveKey="2"
                  style={{ border: "none", backgroundColor: "transparent" }}
                  className="mt-1"
                >
                  <Accordion.Item
                    eventKey="0"
                    style={{ border: "none", backgroundColor: "#2f323b" }}
                  >
                    <Accordion.Header>Network Area</Accordion.Header>
                    <Accordion.Body>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/MyTeam"
                          className="nav-link"
                          aria-current="page"
                        >
                          My Team
                        </NavLink>
                      </li>
                      <li className="nav-item" style={{ width: "100%" }}>
                        <NavLink
                          to="/TreeGenelogy"
                          className="nav-link"
                          aria-current="page"
                        >
                          Genelogy Tree
                        </NavLink>
                      </li>
                      <li className="nav-item mt-2" style={{width:'100%'}}>
                <NavLink to="/SlotGenelogyTree" className="nav-link" aria-current="page">
                  Binary Tree
                  </NavLink>
                </li>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion
                  defaultActiveKey="3"
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  <Accordion.Item
                    eventKey="0"
                    style={{ border: "none", backgroundColor: "#2f323b" }}
                  >
                    <Accordion.Header> Income Area</Accordion.Header>
                    <Accordion.Body>
                      <li className="nav-item" style={{ width: "100%" }}>
                        <NavLink
                          to="/Income"
                          className="nav-link"
                          aria-current="page"
                        >
                          Matching Income
                        </NavLink>
                      </li>
                      {/* <li className="nav-item mt-2" style={{width:'100%'}}>
                  <NavLink to="/GlobalIncomeda" className="nav-link" aria-current="page">
                    Global Income
                  </NavLink>
                </li> */}
                      <li className="nav-item mt-1" style={{ width: "100%" }}>
                        <NavLink
                          to="/ReferralBonus"
                          className="nav-link"
                          aria-current="page"
                          style={{}}
                        >
                          Referral Bonus
                        </NavLink>
                      </li>
                      <li className="nav-item mt-1" style={{ width: "100%" }}>
                        <NavLink
                          to="/LifeTimeRewordIncome"
                          className="nav-link"
                          aria-current="page"
                          style={{ fontSize: "14px", textDecoration: "none" }}
                        >
                          Life Time Reword Income
                        </NavLink>
                      </li>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/DownlineAutomationIncome"
                          className="nav-link"
                          aria-current="page"
                        >
                          Downline Automation Income
                        </NavLink>
                      </li>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/SelfcompleteBonus"
                          className="nav-link"
                          aria-current="page"
                        >
                          Self Complete Bonus
                        </NavLink>
                      </li>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/Network14Income"
                          className="nav-link"
                          aria-current="page"
                        >
                          Network 14 Income
                        </NavLink>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion
                  defaultActiveKey="4"
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  <Accordion.Item
                    eventKey="0"
                    style={{ border: "none", backgroundColor: "#2f323b" }}
                  >
                    <Accordion.Header> Wallet Area</Accordion.Header>
                    <Accordion.Body>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/P2P"
                          className="nav-link"
                          aria-current="page"
                        >
                          P2P Wallet{" "}
                        </NavLink>
                      </li>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/FundConvert"
                          className="nav-link"
                          aria-current="page"
                        >
                          Fund Convert
                        </NavLink>
                      </li>
                      {/* <li className="nav-item mt-2" style={{width:'100%'}}>
                  <NavLink to="/IncomeWallatehistory" className="nav-link" aria-current="page">
                    Income Wallet (History)
                  </NavLink>
                </li> */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion
                  defaultActiveKey="5"
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  <Accordion.Item
                    eventKey="0"
                    style={{ border: "none", backgroundColor: "#2f323b" }}
                  >
                    <Accordion.Header> Topup</Accordion.Header>
                    <Accordion.Body style={{ backgroundColor: "#2f323b" }}>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/Activeid"
                          className="nav-link"
                          aria-current="page"
                        >
                          Activate ID
                        </NavLink>
                      </li>
                      <li className="nav-item mt-2" style={{ width: "100%" }}>
                        <NavLink
                          to="/ActiveHistory"
                          className="nav-link"
                          aria-current="page"
                        >
                          History
                        </NavLink>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Nav>
            </Offcanvas.Body>
            <button
              onClick={() => {
                handleLogout();
              }}
              style={{ color: "#fff" }}
            >
              <i
                class="bi bi-box-arrow-right me-2 "
                style={{ color: "#fff" }}
              ></i>
              Logout
            </button>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
}

export default SideBar;
