import React from 'react'
import SideBar from '../../Sidebar'
import Activeiddata from '../Activeiddata'


const Activeid = () => {
  return (
    <>
  <div className="animated-background">
            <div className='container-fulid' > 
            <div className='row' >
                <div className='col-lg-2 col-md-2 p-0' >
                   <SideBar/>
                </div>
                <div className='col-lg-10 col-md-12 p-0'>
                   <Activeiddata/>
                </div>
             
            </div>
        </div>
        </div>

    </>
  )
}

export default Activeid