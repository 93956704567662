import React from 'react'
import Sidebar from '../Sidebar'
import MyTeamData from './MyteamData'
import TransactionData from './TransactionData'

const Transaction = () => {
  return (
    <>
        <div className="animated-background">
            <div className='container-fulid' > 
            <div className='row'>
                <div className='col-lg-2 col-md-2 p-0' >
                   <Sidebar />
                </div>
                <div className='col-lg-10 col-md-12 justify-content-center align-content-center p-0'>
                <TransactionData/>
                </div>
             
            </div>
        </div>
        </div>
    </>
  )
}

export default Transaction
