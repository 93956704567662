import React ,{useState,useEffect} from 'react'
import { apiurl } from '../env';
import { NavLink } from 'react-router-dom';


const SelfcompleteBonusdata = () => {
const [data, setdata] = useState([])
const [data1, setdata1] = useState([])
const [first, setfirst] = useState(0);
const [loading, setLoading] = useState(false);

const storedName = localStorage.getItem("name");


const global1 =async()=>{
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}` );


var formdata = new FormData();
formdata.append("type", "self");

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(`${apiurl}/get-incomes`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status == '001'){
      setdata1(result.data)
      console(result,'sonu')
     }

  })
  .catch(error => console.log('error', error));
} 

 
useEffect(() => {
  global1()
}, [])


  return (
    <>
<section >
        <div className="container-fluid desktop px-3" style={{background: 'linear-gradient(to right bottom, #4f525c, #2f323b)', width: '100%' }}>
          <div className="row">
            <div className="col-lg-10 col-md-12 p-3">
              <p style={{ textAlign: 'center', color: '#fff', fontSize: '20px' }}>Welcome {storedName}</p>
            </div>
            <div className="col-lg-2 col-md-12">
              <div style={{ width: "100%", padding: "10px" }}>
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle "
                    style={{ color: "#fff", float: "right", fontSize: "25px" }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <h5 className='my mx-3'>Self Complete Bonus</h5>

      <div className='container-fluid mt-5' >
    <div className='row'>
   <div className='col-lg-12'>

   <div className='container' >
<div className='row mt-3 text-center'>
<div className='col-lg-12'>
  
<div class="table-responsive text-nowrap  p-3" 
style={{borderRadius:'10px',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}>
  <div className='res' style={{scrollX: true}}>
  <table class="table table-striped " >
<thead>
<tr>
    <th style={{textAlign:'center',}}>S.no</th>
    <th style={{textAlign:'center',}}>Income From</th>
    <th style={{textAlign:'center',}}>Income To</th>
    <th style={{textAlign:'center',}}>Amount</th>
    {/* <th style={{textAlign:'center',}}>remark</th> */}
    <th style={{textAlign:'center',}}>Income Type</th>
    <th style={{textAlign:'center',}}>Level</th>
    <th style={{textAlign:'center',}}>Date/Time</th>


  </tr>
</thead>
<tbody>
  {data1.length > 0 ? (
    data1.map((res, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{res.income_from}</td>
        <td>{res.income_to}</td>
        <td>{res.amount}</td>
        {/* <td>{res.remark}</td> */}
        <td>{res.income_type}</td>
        <td>{res.level}</td>
        <td>{res.created_at}</td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="8">No data found</td>
    </tr>
  )}
</tbody>
</table>
  </div>
  </div>
</div>
</div>
</div>
 </div>
    </div>
    </div>


    </>
  )
}

export default SelfcompleteBonusdata