import React from 'react'

const DirectsproData = () => {
  return (
    <div>
      <p>cvgbhnm</p>
    </div>
  )
}

export default DirectsproData
