import React, { useEffect, useState } from 'react'
import { apiurl } from '../env';
import {NavLink} from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import { IoIosArrowDropdown } from "react-icons/io";

const MyTeamData = () => {
  const [l1, setl1] = useState([])

  const storedName = localStorage.getItem("name");

const myteam =async()=>{
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var formdata = new FormData();

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(`${apiurl}/my-team`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
  if(result.status =='001'){
    setl1(result.data)
  }
    
  })
  .catch(error => console.log('error', error));
}

  useEffect(()=>{
    myteam();
  },[])
  return (
    <>
<section >
        <div className="container-fluid desktop px-3" style={{background: 'linear-gradient(to right bottom, #4f525c, #2f323b)', width: '100%' }}>
          <div className="row">
            <div className="col-lg-10 col-md-12 p-3">
              <p style={{ textAlign: 'center', color: '#fff', fontSize: '20px' }}>Welcome {storedName}</p>
            </div>
            <div className="col-lg-2 col-md-12">
              <div style={{ width: "100%", padding: "10px" }}>
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle "
                    style={{ color: "#fff", float: "right", fontSize: "25px" }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
<div className='container mt-4'  style={{height:'90vh',overflowY:'scroll',}}>
<div className='row mt-5 p-3'>
<div className='col-lg-12'>
<h5 className='my mx-3' style={{color:'#fff',fontSize:'24px'}}>Level Income</h5>
<div className='ff'>
<Accordion defaultActiveKey="1" style={{border:'none',cursor:'pointer'}}>
      <Accordion.Item eventKey="0" style={{border:'none'}}>
        <Accordion.Header>Level  #1(Direct) </Accordion.Header>
        <Accordion.Body>
        <div class="table-responsive">
  <table class="table">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">User Name </th>
      <th scope="col">number</th>
      <th scope="col">total_invested</th>
      <th scope="col">position</th>
    </tr>
  </thead>
  
  <tbody>
  {l1?.length > 0 ? (
    l1.reduce((accumulator, res) => {
      if (res.lvl === 1) {
        accumulator.count++;
        return (
          accumulator.rows.push(
            <tr key={accumulator.count}>
              <th scope="row">{accumulator.count}</th>
              <td>{res.name}</td>
              <td>{res.username}</td>
              <td>{res.email}</td>
              <td>{res.number}</td>
              <td>{res.total_invested}</td>
              <td>{res.position}</td>
            </tr>
          ),
          accumulator
        );
      }
      return accumulator;
    }, { count: 0, rows: [] }).rows
  ) : (
    <tr>
      <td colSpan="5" style={{ textAlign: 'center' }}>No data found</td>
    </tr>
  )}
</tbody>




</table>
  </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" style={{border:'none'}}>
        <Accordion.Header>Level  #2  </Accordion.Header>
        <Accordion.Body>
        <div class="table-responsive">
  <table class="table">
  <thead>
    <tr>
    <th scope="col">S.No</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">User Name </th>
      <th scope="col">number</th>
      <th scope="col">total_invested</th>
      <th scope="col">position</th>

    </tr>
  </thead>
  
  <tbody>
  {l1?.length > 0 ? (
    l1.reduce((accumulator, res) => {
      if (res.lvl === 2) {
        accumulator.count++;
        return (
          accumulator.rows.push(
            <tr key={accumulator.count}>
              <th scope="row">{accumulator.count}</th>
              <td>{res.name}</td>
              <td>{res.username}</td>
              <td>{res.email}</td>
              <td>{res.number}</td>
              <td>{res.total_invested}</td>
              <td>{res.position}</td>
            </tr>
          ),
          accumulator
        );
      }
      return accumulator;
    }, { count: 0, rows: [] }).rows
  ) : (
    <tr>
      <td colSpan="5" style={{ textAlign: 'center' }}>No data found</td>
    </tr>
  )}
</tbody>



</table>
  </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

    <Accordion defaultActiveKey="2" style={{border:'none'}}>
      <Accordion.Item eventKey="3" style={{border:'none'}}>
        <Accordion.Header>Level #3  </Accordion.Header>
        <Accordion.Body>
        <div class="table-responsive">
  <table class="table">
  <thead>
    <tr>
    <th scope="col">S.No</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">User Name </th>
      <th scope="col">number</th>
      <th scope="col">total_invested</th>
      <th scope="col">position</th>

    </tr>
  </thead>
  
  <tbody>
  {l1?.length > 0 ? (
    l1.reduce((accumulator, res) => {
      if (res.lvl === 3) {
        accumulator.count++;
        return (
          accumulator.rows.push(
            <tr key={accumulator.count}>
              <th scope="row">{accumulator.count}</th>
              <td>{res.name}</td>
              <td>{res.username}</td>
              <td>{res.email}</td>
              <td>{res.number}</td>
              <td>{res.total_invested}</td>
              <td>{res.position}</td>
            </tr>
          ),
          accumulator
        );
      }
      return accumulator;
    }, { count: 0, rows: [] }).rows
  ) : (
    <tr>
      <td colSpan="5" style={{ textAlign: 'center' }}>No data found</td>
    </tr>
  )}
</tbody>

</table>
  </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" style={{border:'none'}}>
        <Accordion.Header>Level #4  </Accordion.Header>
        <Accordion.Body>
        <div class="table-responsive">
  <table class="table">
  <thead>
    <tr>
    <th scope="col">S.No</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">User Name </th>
      <th scope="col">number</th>
      <th scope="col">total_invested</th>
      <th scope="col">position</th>

    </tr>
  </thead>
  <tbody>
  {l1?.length > 0 ? (
    l1.reduce((accumulator, res) => {
      if (res.lvl === 4) {
        accumulator.count++;
        return (
          accumulator.rows.push(
            <tr key={accumulator.count}>
              <th scope="row">{accumulator.count}</th>
              <td>{res.name}</td>
              <td>{res.username}</td>
              <td>{res.email}</td>
              <td>{res.number}</td>
              <td>{res.total_invested}</td>
              <td>{res.position}</td>
            </tr>
          ),
          accumulator
        );
      }
      return accumulator;
    }, { count: 0, rows: [] }).rows
  ) : (
    <tr>
      <td colSpan="5" style={{ textAlign: 'center' }}>No data found</td>
    </tr>
  )}
</tbody>



</table>
  </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

    <Accordion defaultActiveKey="3" style={{border:'none'}}>
      <Accordion.Item eventKey="5" style={{border:'none'}}>
        <Accordion.Header>Level #5  </Accordion.Header>
        <Accordion.Body>
        <div class="table-responsive">
  <table class="table">
  <thead>
    <tr>
    <th scope="col">S.No</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">User Name </th>
      <th scope="col">number</th>
      <th scope="col">total_invested</th>
      <th scope="col">position</th>
    </tr>
  </thead>
  
  <tbody>
  {l1?.length > 0 ? (
    l1.reduce((accumulator, res) => {
      if (res.lvl === 5) {
        accumulator.count++;
        return (
          accumulator.rows.push(
            <tr key={accumulator.count}>
              <th scope="row">{accumulator.count}</th>
              <td>{res.name}</td>
              <td>{res.username}</td>
              <td>{res.email}</td>
              <td>{res.number}</td>
              <td>{res.total_invested}</td>
              <td>{res.position}</td>
            </tr>
          ),
          accumulator
        );
      }
      return accumulator;
    }, { count: 0, rows: [] }).rows
  ) : (
    <tr>
      <td colSpan="5" style={{ textAlign: 'center' }}>No data found</td>
    </tr>
  )}
</tbody>


</table>
  </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
</div>
</div>
</div>
</div>




    </>
  )
}

export default MyTeamData