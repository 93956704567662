import React, { useState } from 'react';
import { apiurl } from '../env';
import { toast, ToastContainer } from 'react-toastify';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import active from '../Img/active-removebg-preview.png';
import '../component/TreeGenelogyData.css';
import p14 from '../Img/Animation - 1705480758874.json';
import Lottie from 'lottie-react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "../component/Text.css";
import congratulationsImage from '../Img/2aa3f37b-305a-4336-846f-9f857b2072b9-removebg-preview.png'

const Activeiddata = () => {

  const Navigate = useNavigate()
  const storedName = localStorage.getItem("name");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [smShow, setSmShow] = useState(false);

    const userActive = async () => {
      try {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: new FormData(),
          redirect: 'follow'
        };
    
        const response = await fetch(`${apiurl}/activate-plan`, requestOptions);
        const result = await response.json();
    
        console.log('API Result:', result);
    
        if (result.status === '001') {
          console.log('Setting smShow to true');
          setSmShow(true); 
          handleClose();
        }
        else if(result.status === "002"){
          toast(result.message); 
        }
        else if(result.status === "007"){
          toast(result.message);
          Navigate('/DepositFund')
        }
        else if(result.status === "009"){
          toast(result.message);
          Navigate('/')
        }
         else {
          toast('Something went wrong');
          handleClose();
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    return (
        <>
            <ToastContainer />
        
      <section>
        <div
          className="container-fluid desktop px-3"
          style={{
            background: "linear-gradient(to right bottom, #4f525c, #2f323b)",
            width: "100%",
          }}
        >
          <div className="row">
            <div className="col-lg-12 col-md-12 p-3">
              <div className="d-flex justify-content-between align-content-center">
                <p style={{ color: "#fff", fontSize: "20px" }}>
                  Welcome {storedName}
                </p>
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle "
                    style={{
                      color: "#fff",
                      float: "right",
                      fontSize: "25px",
                      marginLeft: "90px",
                    }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

            <div className="container mt-5">
                <h1 className="mt-5 text-center" style={{ color: '#fff' }}>User Activate Plan</h1>
                <div className="row">
                    <div className="col-lg-12 mt-3 justify-content-center align-content-center text-center">
                        <img src={active} style={{ width: '200px', height: '150px' }} />
                    </div>
                    <div className="col-lg-12 mt-3 justify-content-center align-content-center text-center">
                        <button className="button-41" role="button" onClick={handleShow}>
                            <span className="text">Activate Now</span>
                        </button>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}  style={{backdropFilter:'blur(8px)',paddingTop:'150px'}}>
                <Modal.Header closeButton style={{background: 'linear-gradient(to right bottom, #4f525c, #2f323b)',color:'#fff'}}>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{background: 'linear-gradient(to right bottom, #4f525c, #000)'}} >
                    <Lottie
                        className="w-25"
                        animationData={p14}
                        loop
                        autoplay
                      style={{marginLeft:'40%'}}
                    />
                    <p style={{ textAlign: "center", color: "#fff", fontSize: "18px" }}>
                        Are you sure you want to activate the plan?
                    </p>
                </Modal.Body>
                <Modal.Footer style={{background: 'linear-gradient(to right bottom, #4f525c, #2f323b)',color:'#fff'}}>
                    <Button variant="secondary" onClick={handleClose}>
                        Go Back
                    </Button>
                    <Button variant="primary" onClick={userActive}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        style={{backgroundColor: smShow ? 'transparent' : '' , backdropFilter:'blur(8px)'}}
        className='sma mt-5'  
      >
        <Modal.Body style={{ background: 'linear-gradient(to right bottom, #4f525c, #000)' }}>
         
         <div style={{display:'flex', justifyContent:'center'}}>
           <img src={congratulationsImage} style={{ width: '200px', height: '100px', justifyContent: 'center', alignItems: 'center', textAlign: 'center',  }} alt="Congratulations" />
           </div>
          <p className='mt-3' style={{ textAlign: "center", color: "#fff", fontSize: "16px", letterSpacing: '1px' }}>
             <b style={{fontSize:'20px'}}>Congrats</b> <br></br> Your Plan  Activated Successfully
          </p>

          <div style={{display:'flex', justifyContent:'center'}} >
          <Button variant="primary" style={{ backgroundColor: '#13132b', }} onClick={() => { setSmShow(false) }}>
            
          <NavLink to='/Home' style={{textDecoration:"none"}}> Back To Dashboard</NavLink>
          </Button>
          </div>
        </Modal.Body>
      </Modal>

        </>
    );
};

export default Activeiddata;