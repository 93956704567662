import React ,{useEffect} from 'react'
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom'
import Home from '../src/component/Home';
import Login from '../src/component/Authentication/Login';
import SignUp from '../src/component/Authentication/SignUp';
import User from './component/User';
import Topbar from './component/Topbar';
import BackgroundVideo from './component/BackgroundVideo';
import ForgotPassword from './component/Authentication/Forgetpass';
import MyTeam from './component/MyTeam';
import MyTeamData from './component/MyteamData';
import Transaction from './component/Transaction';
import TransactionData from './component/TransactionData';
import DepositFund from './component/DepositFund';
import DepositFundData from './component/DepositFundData';
import P2P from './component/P2P';
import P2PData from './component/P2PData';
import PurchasePaln from './component/PurchasePaln';
import PurchasePlandata from './component/PurchasePlandata';
import Income from './component/Income';
import MyChartComponent from './component/MyChartComponent';
import OTP from './component/Authentication/OTP';
import  Password from './component/Authentication/Password';
import TreeGenelogy from './component/TreeGenelogy';
import TreeGenelogyData from './component/TreeGenelogyData';
import Directspro from './component/Directspro';
import DirectsproData from './component/DirectsproData';
import IncomeMatchingData from './component/IncomeMatchingData';
import IncomeMatching from './component/IncomeMatching';
import SlotGenelogyTreeData from './component/SlotGenelogyTreeData';
import SlotGenelogyTree from './component/SlotGenelogyTree';
import GlobalIncomeda from './component/GlobalIncomeda';
import LifeTimeRewordIncome from './component/LifeTimeRewordIncome';
import LifeTimeRewordIncomedata from './component/LifeTimeRewordIncomedata';
import ReferralBonusdata from './component/ReferralBonusdata';
import ReferralBonus from './component/ReferralBonus';
import DownlineAutomationIncomedata from './component/DownlineAutomationIncomedata';
import DownlineAutomationIncome from './component/DownlineAutomationIncome';
import SelfcompleteBonus from './component/SelfcompleteBonus';
import SelfcompleteBonusdata from './component/SelfcompleteBonusdata';
import Network14Income from './component/Network14Income';
import Network14Incomedata from './component/Network14Incomedata';
import FundConvert from './component/FundConvert';
import FundConvertdata from './component/FundConvertdata';
import IncomeWallatehistory from './component/IncomeWallatehistory';
import ActiveHistory from './component/ActiveHistory';
import Activeid from './component/Authentication/Activeid';
import Activeiddata from './component/Activeiddata';


function AllRoute() {

  const Navigate= useNavigate()
  useEffect(() => {
    
    if(!localStorage.getItem('token')){
      if(window.location.pathname !== '/SignUp'){
        Navigate("/")
      }
    }
  }, [])
  
  return (
    <>
      <Routes>
      <Route path='/' element={<Login/>} /> 
        <Route path='/signup' element={<SignUp/>} />
        <Route path='/ForgotPassword' element={<ForgotPassword/>} /> 
        <Route path='/OTP' element={<OTP/>} /> 
        <Route path='/Password' element={<Password/>} /> 
      <Route path='/Home' element={<Home/>} />
      <Route path='/User' element={<User/>} />
      <Route path='/Topbar' element={<Topbar/>} />
      <Route path='/BackgroundVideo' element={<BackgroundVideo/>} />
      <Route path='/MyTeam' element={<MyTeam/>} />
      <Route path='/MyTeamData' element={<MyTeamData/>} />
      <Route path='/Transaction' element={<Transaction/>} />
      <Route path='/TransactionData' element={<TransactionData/>} />
      <Route path='/DepositFund' element={<DepositFund/>} />
      <Route path='/DepositFundData' element={<DepositFundData/>} />
      <Route path='/P2P' element={<P2P/>} />
      <Route path='/P2PData' element={<P2PData/>} />
      <Route path='/PurchasePaln' element={<PurchasePaln/>} />
      <Route path='/PurchasePalndata' element={<PurchasePlandata/>} />
      <Route path='/Income' element={<Income/>} />
      <Route path='/MyChartComponent' element={<MyChartComponent/>} />
      <Route path='/TreeGenelogy' element={<TreeGenelogy/>} />
      <Route path='/TreeGenelogyData' element={<TreeGenelogyData/>} />
      <Route path='/Directspro' element={<Directspro/>} />
      <Route path='/DirectsproData' element={<DirectsproData/>} />
      <Route path='/IncomeMatchingData' element={<IncomeMatchingData/>} />
      <Route path='/IncomeMatching' element={<IncomeMatching/>} />
      <Route path='/SlotGenelogyTreeData' element={<SlotGenelogyTreeData/>} />
      <Route path='/SlotGenelogyTree' element={<SlotGenelogyTree/>} />
      <Route path='/GlobalIncomeda' element={<GlobalIncomeda/>} />
      <Route path='/LifeTimeRewordIncome' element={<LifeTimeRewordIncome/>} />
      <Route path='/LifeTimeRewordIncomedata' element={<LifeTimeRewordIncomedata/>} />
      <Route path='/ReferralBonusdata' element={<ReferralBonusdata/>} />
      <Route path='/ReferralBonus' element={<ReferralBonus/>} />
      <Route path='/DownlineAutomationIncome' element={<DownlineAutomationIncome/>} />
      <Route path='/DownlineAutomationIncomedata' element={<DownlineAutomationIncomedata/>} />
      <Route path='/SelfcompleteBonus' element={<SelfcompleteBonus/>} />
      <Route path='/SelfcompleteBonusdata' element={<SelfcompleteBonusdata/>} />
      <Route path='/Network14Income' element={<Network14Income/>} />
      <Route path='/Network14Incomedata' element={<Network14Incomedata/>} />
      <Route path='/FundConvertdata' element={<FundConvertdata/>} />
      <Route path='/FundConvert' element={<FundConvert/>} />
      <Route path='/IncomeWallatehistory' element={<IncomeWallatehistory/>} />
      <Route path='/ActiveHistory' element={<ActiveHistory/>} />
      <Route path='/Activeid' element={<Activeid/>} />
      <Route path='/Activeiddata' element={<Activeiddata/>} />







      











     </Routes>
    </>
  )
}

export default AllRoute
