import React,{useState,useEffect} from 'react';
import { apiurl } from '../env';
import { toast,ToastContainer} from 'react-toastify';
import { NavLink } from 'react-router-dom';
import SideBar from '../Sidebar';

const IncomeWallatehistory = () => {
    const [first, setfirst] = useState(0);
    const [showSecondForm, setShowSecondForm] = useState(false);
    const [username, setUsername] = useState()
    const [amount, setamount] = useState()
    const [loading, setLoading] = useState(false);
    const [data,setdata] = useState([])
    const [Palnid,setPlanId] = useState()
    const [history, setHistory] = useState([])
    const [fund, setfund] = useState();
    const storedName = localStorage.getItem("name");

    const planhistory = async () => {
      try {
        const response = await fetch(`${apiurl}/my-plans`, {
          method: 'POST',
          headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` },
          body: new FormData(),
        });
        
        const result = await response.json();
  
        if (result.status === '001') {
          setHistory(result.data);
        }
      } catch (error) {
        console.error('Error fetching plan history:', error);
      }
    };
  
    useEffect(() => {
      planhistory();
    }, []);

  return (
<div className='container-fulid'>
<div className='row'>
 <div className='col-lg-2 p-0'>
 <SideBar/>
 </div>
 <div className='col-lg-10 p-0'>
 <div>
  <ToastContainer/>
  <section >
        <div className="container-fluid desktop px-3" style={{background: 'linear-gradient(to right bottom, #4f525c, #2f323b)', width: '100%' }}>
          <div className="row">
            <div className="col-lg-10 col-md-12 p-3">
              <p style={{ textAlign: 'center', color: '#fff', fontSize: '20px' }}>Welcome {storedName}</p>
            </div>
            <div className="col-lg-2 col-md-12">
              <div style={{ width: "100%", padding: "10px" }}>
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle "
                    style={{ color: "#fff", float: "right", fontSize: "25px" }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <h5 className='my mx-3'> Incmoe Wallet History</h5>

    <div className='container-fluid mt-5' >
    <div className='row'>
   <div className='col-lg-12'>
   <div className='container' >
<div className='row mt-3 text-center'>
<div className='col-lg-12'>
  
<div class="table-responsive text-nowrap  p-3" style={{borderRadius:'10px',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}>
  <div className='res'>
  <table class="table table-striped ">
<thead>
  <tr>
    <th>#</th>
    <th>User Id</th>
    <th>Amount</th>
    <th>Status</th>
    <th>Date/Time</th>
  </tr>
</thead>
  <tbody>
  {history.length > 0 ? (
    history.map((res, index) => (
          <tr key={index}>
            <th>{index + 1}</th>
            <th>{res.userid}</th>
            <th>$30</th>
            <th>success</th>
            <th>{res.created_at}</th>
          </tr>
          ))
  ) : (
    <tr>
      <td colSpan="8">No data found</td>
    </tr>
  )}
        </tbody>
</table>
  </div>
  </div>
</div>
</div>
</div>
            </div>
    </div>
    </div>

    </div>
 </div>
</div>

</div>

  )
}

export default  IncomeWallatehistory