import React, { useState } from 'react';
import { CiSquarePlus } from 'react-icons/ci';
import { apiurl } from '../env';
import { NavLink } from 'react-router-dom';

const DynamicTreeNode = ({ label, icon, fetchData, parentUsername }) => {
  const [children, setChildren] = useState([]);

  const handleTreeIconClick = async () => {
    try {
      const data = await fetchData(label, parentUsername); // Pass username and id
      if (data) {
        const newChildren = data.map((child) => ({
          label: child.username, 
          position: child.position, 
        }));
        setChildren(newChildren);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <li style={{ overflowX: 'scroll', width: '100%' }}>
      <span style={{ cursor: 'pointer' }}>{label}</span>
      {icon && (
        <p
          className='ms-2'
          style={{
            fontSize: '25px',
            marginTop: '-16px',
            marginRight: '20px',
            cursor: 'pointer',
          }}
          onClick={handleTreeIconClick}
        >
          {icon}
        </p>
      )}
      {children.length > 0 && (
        <ul>
          {children.map((child, index) => (
            <li key={index} className='text-white'>
              {/* Pass parentUsername and parentId to the child DynamicTreeNode */}
              <DynamicTreeNode  
                label={`${child.label} ( ${child.position === 'left' ? 'L' : 'R'} )`} 
                icon={<CiSquarePlus />}
                fetchData={fetchData}
                parentUsername={child.label} // Pass the clicked label as parentUsername
                
              />
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

const DynamicTree = () => {
  const [data, setData] = useState([]);
  const storedName = localStorage.getItem("name");
  const fetchData = async (parentLabel, parentUsername = null  ) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

      const queryParams = new URLSearchParams();
      if (parentUsername) {
        queryParams.append('username', parentUsername);
      } 
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      const response = await fetch(`${apiurl}/binary-tree?${queryParams.toString()}`, requestOptions);
      const result = await response.json();

      if (result.status === 1) {
        setData(result.data);
        return result.data;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  return (

    
    <div className='container-fluid m-auto'>
      <div className='row'>
        <div className='col-12'>

        <div className="container-fluid desktop px-3 mt-1" style={{background: 'linear-gradient(to right bottom, #4f525c, #2f323b)', width: '100%' }}>
          <div className="row">
            <div className="col-lg-10 col-md-12 p-3">
              <p style={{ textAlign: 'center', color: '#fff', fontSize: '20px' }}> Welcome {storedName}</p>
            </div>
            <div className="col-lg-2 col-md-12">
              <div style={{ width: "100%", padding: "10px" }}>
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle "
                    style={{ color: "#fff", float: "right", fontSize: "25px" }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
          <div className='root-container'>
            <figure className='mt-5' style={{ height: '90vh', overflowY: 'scroll', overflowX: 'scroll' }}>
              <figcaption style={{ fontSize: '20px', color: '#fff', letterSpacing: '1px' }}>
                Binary Tree
              </figcaption>
              <ul className="tree mt-4 text-white" style={{ display: 'flex', justifyContent: 'center',   }}>
                <div className="centered-content" style={{overflowX:'scroll', overflowY:'scr'}}>
                  {/* Pass the label (username) as parentUsername for the root node */}
                  <DynamicTreeNode
                    label="ROOT"
                    icon={<CiSquarePlus />}
                    fetchData={fetchData}
                    parentUsername=""
                  />
                </div>
              </ul>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicTree;
