import React,{useState,useEffect} from 'react';
import { apiurl } from '../env';
import { toast,ToastContainer} from 'react-toastify';
import { Password } from '@mui/icons-material';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';


const P2PData = () => {
  const [searchParams] = useSearchParams();

    const [first, setfirst] = useState(0);
    const [showSecondForm, setShowSecondForm] = useState(false);
    const [username, setUsername] = useState(searchParams.get("refid") || '')
    const [amount, setamount] = useState()
    const [password, setPassword] = useState()
    const [loading, setLoading] = useState(false);
    const [data,setdata] = useState([])
    const [fund, setfund] = useState()
    const [fund1, setfund1] = useState()
    const storedName = localStorage.getItem("name");
    const [refName, setRefName] = useState(searchParams.get("name") || '');
const P2Psend = async()=>{
  setLoading(true)
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var formdata = new FormData();
formdata.append("amount", amount);
formdata.append("userid", username);
formdata.append("password", password);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(`${apiurl}/p2p-transfer`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
    if (result.status === '001') {
      setUsername('');
      setPassword('');
      setamount('');
      toast(result.message);
      window.location.reload()
    } else {
      toast(result.message);
    }
  })
  .catch(error => console.log('error', error)).finally(()=>{setLoading(false)})
}



const P2Phistory =async()=>{
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);


var formdata = new FormData();

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(`${apiurl}/p2p-history`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
    if(result.status == 1){
      setdata(result.data)
      setfund(result.fund_balance)
      setfund1(result.wallet_balance)
    }
  })
  .catch(error => console.log('error', error));
}


useEffect(() => {
  // Make API call when refid changes
  const fetchData = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);


      const formdata = new FormData();
      formdata.append("referId", username);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      const response = await fetch(`${apiurl}/get-user`, requestOptions);
      const result = await response.json();
      console.log(result);
      setRefName(result.name);
    } catch (error) {
      console.log('error', error);
    }
  };

  fetchData();
}, [username])

const handleInputChange = (e) => {
  setUsername(e.target.value);
  
};
useEffect(() => {
  P2Phistory()
}, [])

  return (
  <div>
  <ToastContainer/>
  <section >
        <div className="container-fluid desktop px-3" style={{background: 'linear-gradient(to right bottom, #4f525c, #2f323b)', width: '100%' }}>
          <div className="row">
            <div className="col-lg-10 col-md-12 p-3">
              <p style={{ textAlign: 'center', color: '#fff', fontSize: '20px' }}>Welcome {storedName}</p>
            </div>
            <div className="col-lg-2 col-md-12">
              <div style={{ width: "100%", padding: "10px" }}>
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle "
                    style={{ color: "#fff", float: "right", fontSize: "25px" }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    <h5 className='my mx-3'>P 2 P Transfer</h5>
    <div className='container-fluid mt-5'  style={{ height: "88vh", overflowY: "scroll", }}>
    <div className='row'>
   <div className='col-lg-12'>
   <div className='d-flex tabs-container'>
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => setfirst(0)}
          className={first === 0 ? 'active' : ''}
        >
          <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>P2P Transfer</p>
        </h6>
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => setfirst(1)}
          className={first === 1 ? 'active' : ''}
        >
        <p className='px-3 mt-2' style={{color:"#fff",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>Transfer History</p>

        </h6>
      </div>

        {
        first==0?<>
               <div className='mt-5'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-1 col-md-6 col-sm-6">
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 text-center">
                        <div class="card1" style={{width:"100%",padding:'20px'}}>
                            <div class="ca">
                                <h5 class="cp">Fund  Balance</h5>
                                <p style={{color:"#fff",borderBottom:'1px solid #fff'}}/>
                                <h5 class="ca">${fund}</h5>
                            </div>
                        </div>
                        <div class="card1 mt-4" style={{width:"100%",padding:'20px'}}>
                            <div class="ca">
                                <h5 class="cp"> Wallet Balance</h5>
                                <p style={{color:"#fff",borderBottom:'1px solid #fff'}}/>
                                <h5 class="ca">${fund1}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-6 col-sm-6">
                    </div>
                    <div className='col-lg-6 mt-2'>
 <div style={{ background: '#22223',borderRadius:"10px",
  boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}>
<div style={{background: 'linear-gradient(to right bottom, #4f525c, #2f323b)',borderRadius:'1px'}}>
<p style={{padding:'15px',color:'#fff',fontWeight:600,fontSize:"18px"}}>P2P Transfer</p>
</div>
        <div>
      {!showSecondForm && (
        <div className="mb-3 p-4">
          <label htmlFor="exampleFormControlInput1" className="f">
            Userid
          </label>
          <input
    style={{color:'blue'}}
    type="text"
    value={username}
    onChange={handleInputChange}
    className="form-control"
    id="exampleFormControlInput1"
    placeholder="Enter User ID"
/>
<p style={{color:'#fff'}}>{refName == null ? 'undefined' : `${refName}`}</p>

<label htmlFor="exampleFormControlInput2" className="f mt-3">
            Enter Password
          </label>
          <input
          style={{color:'blue'}}
          value={password}
          onChange={(e)=>{setPassword(e.target.value)}}
            type="text"
            className="form-control"
            id="exampleFormControlInput2"
            placeholder="Enter password"
          />

          <label htmlFor="exampleFormControlInput2" className="f mt-3">
            Enter Amount
          </label>
          <input
          style={{color:'blue'}}
          value={amount}
          onChange={(e)=>{setamount(e.target.value)}}
            type="number"
            className="form-control"
            id="exampleFormControlInput2"
            placeholder="Enter Amount"
          />

          {/* <label htmlFor="exampleFormControlInput3" className="f mt-3">
            Coin:
          </label>
          <select className="form-select mt-2" aria-label="Default select example">
            <option selected>Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select> */}

          <button className="btn1 mt-4" onClick={()=>{P2Psend()}} style={{cursor:'pointer'}}>
          {loading ? 'Loading...' : 'Procced'}
          </button>
        </div>
      )}
    </div>  
    </div>
</div>
                </div>
            </div>
            </div>
        </>:first==1?<>
        <div className='container' >
<div className='row mt-3 text-center'>
<div className='col-lg-12'>
  
<div class="table-responsive text-nowrap m-3 p-3" style={{borderRadius:'10px',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}>
  <div className='res'>
  <table class="table table-striped ">
<thead>
  <tr>
    <th>#</th>
    <th>fromuser</th>
    <th>touser</th>
    <th>amount</th>
    <th>created_at</th>
  </tr>
</thead>
<tbody>
  {data.length > 0 ? (
    data.map((res, index) => (
      <tr key={index}>
        <th>{index + 1}</th>
        <th>{res.fromuser}</th>
        <th>{res.touser}</th>
        <th>{res.amount}</th>
        <th>{res.created_at}</th>

      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="8">No data found</td>
    </tr>
  )}
</tbody>
</table>
  </div>
  </div>
</div>
</div>
</div>

        </>:null
    }
            </div>
    </div>
    </div>

    </div>

  )
}

export default  P2PData
    