import React,{useId, useState}from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './Login.css'
import { Button } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { apiurl } from '../../env';
import { ToastContainer, toast } from 'react-toastify';


function ForgotPassword() {
  const Navigate = useNavigate()
  const [email, setemail] = useState()
  const [userid, setuserId] = useState()
  const [loading, setLoading] = useState(false);


  const Forgetpass = async () => {
    setLoading(true);
  
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
  
      localStorage.setItem("userid", userid);
      var formdata = new FormData();
      formdata.append("userId", userid);
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
  
      const response = await fetch(`${apiurl}/forget-password`, requestOptions);
      const result = await response.json();
  
      if (result.status === '001') {
        toast.success(result.message);
        await new Promise(resolve => setTimeout(resolve, 1000));
        Navigate('/OTP'); 
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log('error', error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  



  return (
  
<>
<div>
  <ToastContainer/>
</div>
   <div class="container app">

<div class="login">

    <h1>Forgot Password</h1>

    <div action="" className='form'>
        <div class="input-box">
        </div>

        <div class="input-box mt-3">
        <label htmlFor="username" style={{color:'#fff'}}>Enter Your Username</label>
            <input 
            value={userid}
            onChange={(e)=>{setuserId(e.target.value)}}
            type="username"
             placeholder="username"/>
          
        </div>

        <button onClick={()=>{Forgetpass()}} className="sumit mt-3">
            {loading==false?"Send OTP":"Loading...."}
            </button>

        <div className="">
              <p style={{color:'#000'}}>
                Don't have an account? <NavLink to="/">Login</NavLink>
              </p>
            </div>

    </div>

</div>
</div>
</>
  )
}

export default ForgotPassword
