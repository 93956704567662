import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { apiurl } from '../../env';
import { toast, ToastContainer } from 'react-toastify';
import OtpInput from 'react-otp-input'



const OTP = () => {
  const Navigate = useNavigate()
    const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userid");



  const Otp = async()=>{
    setLoading(false)
    var myHeaders = new Headers();

var formdata = new FormData();
formdata.append("otp", otp);
formdata.append("username", userId);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(`${apiurl}/validate-otp`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
   if(result.status){
     toast.success(result.message)
    Navigate('/Password')
    setOtp('')
    localStorage.setItem("token", result.token);

   }
   else{
    toast.error(result.message)

   }
  })
  .catch(error => console.log('error', error)).finally(()=>{setLoading(false)})

  }

  const renderInput = (inputProps) => (
    <input
      {...inputProps}
      style={{
        fontSize: '20px', 
        textAlign: 'center',
        color: '#000', // Your desired text color
        backgroundColor: 'rgba(255, 255, 255, 0.2)  ',
        border: '1px solid #ccc',
        borderRadius: '5px',
        
      }}
    />
  );

  console.log(otp);

  return (
    <>
    <div>
      <ToastContainer/>
    </div>
       <div class="container app">
    
    <div class="login">
    
        <h1>OTP</h1>
    
        <div action="" className='form'>
            <div class="input-box">
            </div>
    
            <div class="input-box mt-3">
            <label htmlFor="username" style={{color:'#fff'}}>Enter Your OTP</label>
            <OtpInput
            
      value={otp}
      onChange={setOtp}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={renderInput}
    />

            </div>
    
            <button className="sumit mt-4" onClick={()=>{Otp()}}>
                {loading==false?"Confime OTP":"Loading...."}
                </button>
    
            <div className="register-link">
                  <p style={{color:'#fff'}}>
                    Don't have an account? <NavLink to="/">Login</NavLink>
                  </p>
                </div>
    
        </div>
    
    </div>
    </div>
    </>
  )
}

export default OTP